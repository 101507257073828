<template>
  <div class="loan_item">
    <div class="inner inner0_5" />

    <div class="inner inner2">
      <!-- <h4>Sobre nosotros</h4> -->
      <div class="content">
        <div class="left_cell">
          <h1>About Eagle Cash</h1>
          <p>At Eagle Cash, we’re committed to helping individuals
            and businesses access the funds they need to achieve
            their goals. Our company has been providing reliable lending
            services to customers across the country. With a team of
            experienced professionals and a strong focus on customer
            service, we’re dedicated to providing fast, hassle-free loans
            that can help you overcome financial obstacles and achieve
            your dreams. We’re proud to be a trusted name in the lending
            industry, and we look forward to helping you achieve financial
            success.
          </p>
          <!-- <p>
            To allow customers currently not being served by
            traditional banks to become active in the microcredit
            ecosystem and enable them to build a credit identity;
            in an easy, trustworthy & fast manner.
          </p> -->
        </div>
        <div class="right_cell">
          <img src="@/assets/images/loanImg/about_inner2.png" alt="">
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <div class="content">
        <div class="right_cell">
          <img src="@/assets/images/loanImg/about_inner3.png" alt="">
        </div>
        <div class="left_cell">
          <h1>Vision & Mission</h1>
          <p>To open the inclusion of short-term digital credit to
            the unbanked & general population.To allow customers
            currently not being served by traditional banks to become
            active in the micro credit ecosystem and enable them to
            build a credit identity; in an easy, trustworthy & fast manner.
          </p>
          <!-- <p>SEC Registration No. CS201911897</p>
          <p>REGISTERED BUSINESS NAME: Microloan</p>
          <p>Authorization Certificate No. 3037</p> -->
        </div>
      </div>
    </div>
    <div class="inner inner6">
      <div class="inner6_item content">
        <div class="inner6_item_left">
          <div>
            <h1 class="inner6_item_left_title">How To Contact Us</h1>
            <div v-for="(item,index) in inner6ItemLeftList" :key="index" class="inner6_item_left_list ">
              <img :src="item.img">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
        <div class="inner6_item_right"><img src="@/assets/images/kefu.png" alt=""></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner6ItemLeftList: [
        { title: 'eaglecashservice@outlook.com', img: require('@/assets/images/email.png') },
        { title: 'Ikeja 101233, Ikeja, Lagos', img: require('@/assets/images/address.png') },
        { title: '9:00 - 17:00', img: require('@/assets/images/time.png') }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    min-width: 1100px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 18px;
      color: #3E1FA5;
    }
    .content{
      padding: 50px calc((100% - 1100px) / 2) ;
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #6E60F9;
      }
    }
  }
  .inner0_5{
    background: url('~@/assets/images/loan_bg.png') no-repeat;
    background-size: 100% 100%;
    height: 600px;
    // background: #3E1FA5;
    // padding: 50px calc((100% - 1500px) / 2)  0 calc((100% - 1800px) / 2);
    display: flex;
    p{
      font-size: 15px;
      font-family: Arial-Regular, Arial;
      color: #FFFFFF;
      width:calc(38%);
      line-height: 30px;
    }
    div{
      h1{
        color: white;
        font-size: 40px;
        line-height: 150px;
        margin-top: 60px;
      }
      p{
        width: 100%;
        background: #644FFA;
        padding: 25px 25px;
        line-height: 25px
      };
    }
  }
  .inner2{
    .content{
      display: flex;
      .left_cell,.right_cell{
        flex: 1;
        display: flex;
      }
      h1{
        font-size: 30px;
      }
      img{
        margin:auto 0;
        width: 420px;
        height: 230px;
      }
      .left_cell{
        display: flex;
        flex-direction: column;
        p{
          font-size: 12px;
          line-height: 25px;
          color: black;
          padding: 0 170px 0px 0;
        }
      }
      .right_cell{
        display: flex;
        justify-content: right;
      }
    }
  }

  .inner3{
    background: #F2F4F8;
    .content{
      display: flex;
      .left_cell,.right_cell{
        flex: 1;
        display: flex;
        // justify-content: center;
      }
      h1{
        font-size: 30px;
      }
      img{
        margin: 25px 0;
        width: 420px;
        height: 240px;
        // display: block;
      }
      .left_cell{
        display: flex;
        flex-direction: column;
        margin-left: 260px;
        p{
          font-size: 12px;
          line-height: 27px;
          color: black;
        }
      }
    }
  }
  .inner6{
    .inner6_item{
      align-items: center;
      .inner6_item_left{
        z-index: 2;
        // border-radius: 10px;
        background: #000000;
        color: #ffffff;
        padding: 40px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        width: 50%;
        h1{
          text-align:left;
          margin-bottom: 26px;
          width: auto;
        }
        .inner6_item_left_list{
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          img{
            width: 24px;
            height: 24px;
            margin-right: 18px;
          }
        }

      }
        .inner6_item_right{
          width: 55%;
          z-index: 1;
          margin-left: -40px;
          img{
            padding: 2px;
            // border: 2px dashed rgb(206, 206, 206);
            width: 100%;
          }
        }
    }

  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    .inner{
      min-width: calc(100vw) !important;
    }
    .inner0_5{
      height: 200px;
      color: white;
      padding: 50px 20px 0;
      flex-direction: column;
    }

  .inner2{
    h4{
      padding: 0 20px;
    }
    .content{
      padding: 0 20px !important;
      display: flex;
      flex-direction: column;
      .left_cell{
        padding: 30px 0;
        h1{
          text-align: center;
          margin-bottom: 20px;
        }
        p{
          padding: 0 !important;
          width: 100% !important;
          margin:  15px 0;
        }
      }
      .right_cell{
        img{
        width: 100% !important;
        }
      }
    }
  }
    .inner3{
      // padding-top: 20px;
      margin: 30px 0;
      h4{
        padding: 0 20px;
      }
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          width: 100%;
          margin-left: 0px;
          padding: 30px 0;
          p{
            padding: 0 !important;
            width: 100% !important;
            // margin:  10px 0;
          }
        }
        .right_cell{
          img{
          width: 100% !important;
          }
        }
      }
    }
  }
  .inner6{
    // padding: 30px 20px 30px;
    h3{
      text-align: center;
      margin-bottom: 30px;
    }
    .inner6_item{
      width: 100%;
      flex-direction: column;
      .inner6_item_left{
        width: calc(100% - 40px) !important;
        padding: 20px 0;
        .inner6_item_left_title{
          font-size: 20px !important;
          text-align: center;
        }
        .inner6_item_left_list{
          justify-content: left;
          img{
            width: 17px;
            height: 17px;
          }
          span{
            font-size: 12px;
          }
        }
      }
      .inner6_item_right{
        width: calc(100% - 80px) !important;
        margin-left: 0 !important;
        margin: -20px 0 0;
        z-index: 2;
      }
    }
  }
}
</style>
